// link required packages
import $ from 'jquery';
window.jQuery = $; window.$ = $;

import '../javascripts/matchHeight.js';
import '../javascripts/jquery.lightbox_me.js';
import 'animsition'
import 'cookieconsent'

// link app javascripts
import '../javascripts/application.js';

// link stylesheets
import '../stylesheets/application.scss';
